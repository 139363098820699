import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const MailingApiService = {
  async getMailingConfirmations({
    pageNum = 1, pageSize = 10, orderId = null, accountId = null,
  }) {
    try {
      const { data: { items } } = await HTTP.get('mailing-confirmations', {
        params: {
          pageNum, pageSize, orderId, accountId,
        },
      });
      return Promise.resolve(items || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getMailingConfirmationDownloadLink({ id }) {
    try {
      const { data: { url } } = await HTTP.get(`mailing-confirmations/${id}/download`);
      return Promise.resolve(url || '');
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

<template>
  <v-layout column>
    <ir-mailing-confirmations-search
      ref="mailingSearch"
      @search="getMailingConfirmations"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="!mailingConfirmations.length"
      title="Mailing Confirmations"
    >
      <ir-mailing-confirmations-list :items="mailingConfirmations" />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { usePagination } from '@mixins/factories';
import { dataLoadingState } from '@mixins';

import { wrapToLoadingFn } from '@helpers';

import { IrMailingConfirmationsSearch, IrMailingConfirmationsList } from '../_components';
import { MailingApiService } from '../_services';

const paginationOptions = { itemsName: 'mailingConfirmations' };

export default {
  name: 'IrMailing',

  components: {
    IrMailingConfirmationsSearch,
    IrMailingConfirmationsList,
  },

  mixins: [
    usePagination(paginationOptions),
    dataLoadingState,
  ],

  data() {
    return {
      mailingConfirmations: [],
      loading: false,
    };
  },

  methods: {
    getMailingConfirmations(params) {
      this.$_setDataLoadingPending();

      this.wrapToLoadingFn({
        req: MailingApiService.getMailingConfirmations.bind({}, params),
        loadingFlagName: dataLoadingState.$_meta.LOADING_FLAG_NAME,
        onSuccess: response => this.$set(this, 'mailingConfirmations', response),
        onReject: e => this.$_setDataLoadingError(e),
      });
    },

    wrapToLoadingFn,
  },
};
</script>

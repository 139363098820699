<template>
  <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      title="Search Mailing Confirmations"
      @reset="onResetSearch"
      @search="onSearch"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { useDataLoading, useSearch } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'handleAfterSearch' };

const searchOptions = {
  initialSearchDataStates: ['orderId', 'accountId'],
  customTypes: {
    accountId: 'number',
  },
};

export default {
  name: 'IrMailingSearch',

  mixins: [
    useDataLoading(dataLoadingOptions),
    useSearch(searchOptions),
  ],

  created() {
    this.getAccounts();
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions']),

    searchFieldsConfig() {
      return [
        {
          value: 'orderId',
          label: 'Order ID',
          type: 'text',
          flex: 'lg6',
        },
        {
          value: 'accountId',
          label: 'Account',
          type: 'combobox',
          items: this.accountsOptions,
          flex: 'lg6',
        },
      ];
    },
  },

  methods: {
    ...mapActions('accounts', {
      getAccounts(dispatch, isAllAccounts = false) {
        dispatch('getAccounts', { activeOnly: !isAllAccounts });
      },
    }),

    handleAfterSearch(params) {
      this.$emit('search', params);
    },
  },
};
</script>

<template>
  <v-layout>
    <vbt-table
      :items="items"
      :headers="headers"
    >
      <template #orderId="{ item: { orderId: id } }">
        <router-link
          v-if="id"
          :to="{
            name: 'order',
            params: { id },
          }"
          class="blue--text darken-1"
        >
          {{ id }}
        </router-link>
        <span v-else>
          -
        </span>
      </template>

      <template #createDate="{ item: { createDate: date } }">
        {{ formatDisplayDate(date) }}
      </template>

      <template #processDate="{ item: { processDate: date } }">
        {{ formatDisplayDate(date) }}
      </template>

      <template #state="{ item: { state } }">
        {{ mailingConfirmationProcessingStateByEnum[state] }}
      </template>

      <template #actions="{ item }">
        <v-btn
          color="info"
          x-small
          icon
          @click="downloadFile(item.id)"
        >
          <v-icon>
            file_download
          </v-icon>
        </v-btn>
      </template>
    </vbt-table>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { MailingApiService } from '../_services';

const headers = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Account', value: 'accountName' },
  { text: 'Create Date', value: 'createDate' },
  { text: 'Process Date', value: 'processDate' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Amount', value: 'amount' },
  { text: 'State', value: 'state' },
  { text: '', value: 'actions' },
]);

export default {
  name: 'IrMailingConfirmationsList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapGetters('enums', ['mailingConfirmationProcessingStateByEnum']),
  },

  methods: {
    downloadFile(id) {
      this.wrapToLoadingFn({
        req: MailingApiService.getMailingConfirmationDownloadLink.bind({}, { id }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: url => window.open(url, '_blank'),
      });
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('vbt-table',{attrs:{"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [(id)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
          name: 'order',
          params: { id: id },
        }}},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"createDate",fn:function(ref){
        var date = ref.item.createDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(date))+" ")]}},{key:"processDate",fn:function(ref){
        var date = ref.item.processDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(date))+" ")]}},{key:"state",fn:function(ref){
        var state = ref.item.state;
return [_vm._v(" "+_vm._s(_vm.mailingConfirmationProcessingStateByEnum[state])+" ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","x-small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item.id)}}},[_c('v-icon',[_vm._v(" file_download ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }